import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Calendar, Email, Gift } from 'app/components/Icons'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  icon?: 'calendar' | 'email' | 'gift'
  languageCode?: string
  languagePrefix?: string | null
  target?: string
  title: string
  url: string
}

export const BottomMenuItem = memo(function BottomMenuItem({
  icon,
  target,
  title,
  url,
}: Props) {
  if (!url) {
    return null
  }

  const renderIcon = () => {
    switch (icon) {
      case 'calendar':
        return <Calendar />
      case 'email':
        return <Email />
      case 'gift':
        return <Gift />
    }
  }

  return (
    <>
      {target ? (
        <External href={url} rel="noopener" target={target}>
          {icon ? renderIcon() : null}
          {title ? title : undefined}
        </External>
      ) : (
        <Anchor to={url}>
          {icon ? renderIcon() : null}
          {title ? title : undefined}
        </Anchor>
      )}
    </>
  )
})

const Style = css`
  display: inline-flex;
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.heading};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-right: 5.3125rem;
  &:hover {
    color: ${theme.colors.variants.secondaryLight};
  }
  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: auto;
    height: 1.25rem;
    margin-right: 0.75rem;
    opacity: 0.3;
    fill: none;
    stroke: ${theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
  }
`

const Anchor = styled(Link)`
  ${Style}
`

const External = styled.a`
  ${Style}
`
