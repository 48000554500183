import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { NewsletterFormSender } from 'app/utils/NewsletterFormSender'
import React, { memo, useMemo } from 'react'

import { Bottom } from './Bottom'
import { Contacts } from './Contacts'
import { Head } from './Head'
import { Props as MenuItemProps } from './MenuItem'
import { Newsletter } from './Newsletter'
import { Social } from './Social'

export interface Props {
  address?: string
  addressImage?: ImageProps
  bookingPhone?: string
  email?: string
  facebookURL?: string
  googleMapsURL?: string
  infoPhone?: string
  instagramURL?: string
  languageCode: string
  logo?: string
  logoMira?: string
  mapImage?: ImageProps
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  siteName?: string
  vat?: string
  whatsapp?: string
  variant?: Variant
}

export const Footer = memo(function Footer({
  address,
  addressImage,
  bookingPhone,
  email,
  facebookURL,
  googleMapsURL,
  infoPhone,
  instagramURL,
  languageCode,
  logo,
  logoMira,
  mapImage,
  policyMenu,
  siteName,
  vat,
  whatsapp,
  variant = 'default',
}: Props) {
  const sendFormTo = useMemo(
    () =>
      NewsletterFormSender({
        formURL: 'https://a1e5x4.emailsp.com/frontend/subscribe.aspx',
      }),
    [],
  )

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Wrapper>
          <Head
            address={address}
            addressImage={addressImage}
            googleMapsURL={googleMapsURL}
            languageCode={languageCode}
            mapImage={mapImage}
            siteName={siteName}
            variant={variant}
          />

          <Contacts
            bookingPhone={bookingPhone}
            email={email}
            infoPhone={infoPhone}
            languageCode={languageCode}
            logo={logo}
            logoMira={logoMira}
            siteName={siteName}
          />

          <Social
            facebookURL={facebookURL}
            instagramURL={instagramURL}
            whatsapp={whatsapp}
          />

          <Media lessThan="ipadHorizontal">
            {logoMira ? (
              <LogoLink
                href="https://www.mirahotels.com"
                rel="noopener"
                target="_blank"
              >
                <Logo src={logoMira} alt={siteName} width="113" height="90" />
              </LogoLink>
            ) : null}
          </Media>

          <Newsletter
            languageCode={languageCode}
            onNewsletterFormSendTo={async (data) => await sendFormTo(data)}
          />

          <Bottom
            languageCode={languageCode}
            policyMenu={policyMenu}
            vat={vat}
          />
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 0 11.944vw 2.5rem;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
`

const Wrapper = styled.div``

const LogoLink = styled.a`
  display: inline-block;
  margin: 3.75rem auto 0;
`

const Logo = styled.img``

export type Variant = 'default' | 'cta' | 'slider'
