import styled from '@emotion/styled'
import { Image } from 'app/components/Common/Image'
import { HeaderContext } from 'app/containers/Header'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { motion } from 'framer-motion'
import React, { memo, useContext } from 'react'

import { BottomMenuItem, Props as BottomMenuItemProps } from './BottomMenuItem'
import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  bottomMenuItems?: BottomMenuItemProps[]
  menuItems: MenuItemProps[]
  modalStatus?: boolean
}

export const Navigation = memo(function Navigation({
  bottomMenuItems,
  menuItems,
  modalStatus,
}: Props) {
  if (!menuItems) {
    return null
  }

  const halfMenuItems = Math.ceil(menuItems.length / 2)

  const { menuImage } = useContext(HeaderContext) as any

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1,
        stiffness: 1000,
        velocity: -100,
      },
    }),
    hidden: { opacity: 0, x: -60 },
  }

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {menuItems ? (
          <Nav>
            <NavList>
              {menuItems.slice(0, halfMenuItems).map((item, index) => (
                <NavLi
                  key={index}
                  custom={index}
                  animate={modalStatus ? 'visible' : 'hidden'}
                  variants={variants}
                >
                  <MenuItem {...item} />
                </NavLi>
              ))}
            </NavList>
            <NavList>
              {menuItems.slice(halfMenuItems).map((item, index) => (
                <NavLi
                  key={index}
                  custom={index}
                  animate={modalStatus ? 'visible' : 'hidden'}
                  variants={variants}
                >
                  <MenuItem {...item} />
                </NavLi>
              ))}
              <NavLi
                animate={modalStatus ? 'visible' : 'hidden'}
                custom={menuItems.length - 1}
                variants={variants}
              >
                <ChainLink
                  className="chain-link"
                  href="https://www.mirahotels.com"
                  target="_blank"
                  rel="noopener"
                >
                  Mira Hotels
                </ChainLink>
              </NavLi>
            </NavList>
          </Nav>
        ) : null}

        <Media greaterThanOrEqual="desktopSmall">
          {bottomMenuItems ? (
            <BottomMenu>
              {bottomMenuItems.map((item, index) => (
                <BottomMenuItem key={index} {...item} />
              ))}
            </BottomMenu>
          ) : null}

          <Background>
            {modalStatus ? <Image {...menuImage} /> : null}
          </Background>
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  overflow-y: auto;
  padding-bottom: 7.5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: auto;

  @media (max-width: 1199px) {
    height: calc(100% - 3.75rem);
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    padding-bottom: 0;
    top: 3.75rem;
  }
`

const Nav = styled.nav`
  display: flex;
  width: calc(100% - 25.833vw);
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 13.25rem 0 0 10.278vw;

  @media (max-width: 1199px) {
    display: block;
    width: 100%;
    background: none;
    padding: 4.25rem 0 7.5rem;
  }
`

const NavList = styled.ul`
  width: 50%;
  padding-bottom: 3.75rem;

  @media (max-width: 1199px) {
    width: 100%;
    margin-right: auto;
    padding-bottom: 0;
    &:last-of-type {
      margin-top: 2rem;
    }
  }
`

const NavLi = styled(motion.li)`
  margin-top: 2rem;
  padding: 0 4.25rem;
  &:first-of-type {
    margin-top: 0;
  }
  &.ibe-anchor {
    display: none;
  }

  @media (max-width: 1199px) {
    opacity: 1 !important;
    transform: none !important;
    &.ibe-anchor {
      display: block;
    }
  }

  @media (max-width: 374px) {
    padding: 0 2.5rem;
  }
`

const ChainLink = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-transform: uppercase;
  transition: 0.2s ease-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.secondaryLight};
  }

  @media (max-width: 1199px) {
    font-weight: 400;
  }
`

const BottomMenu = styled.div`
  width: calc(100% - 25.833vw);
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 3.75rem 10.278vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;

  > div {
    display: flex;
    flex-wrap: wrap;
  }
`

const Background = styled.div`
  width: 25.833vw;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
  }
`
